import * as yup from 'yup';
import { ComponentTypes } from 'common/ComponentTypes';
import { FormSchemaType } from 'common/types/FormSchemaType';
import { OverrideProps } from 'components/button/ExpandingArrowButton';

export interface FormMapperType {
  [key: string]: {
    id: string;
    name?: string;
    subtitle?: string;
    description: string;
    formSchema: FormSchemaType;
    validationSchema: any;
    defaultValues: any;
    saveText?: string;
    buttonOverrides?: OverrideProps;
    sendFileOnSubmission?: string;
    submissionEmailSubject?: string;
    submissionFileName?: string;
  };
}
const defaultButtonOverrides: OverrideProps = {
  bgColor: '#1e365c',
  textColor: '#ffffff',
  hoverBgColor: '#00C4DE',
  hoverTextColor: '#1e365c',
  borderColor: '#1e365c'
};

export const FormMapper: FormMapperType = {
  'contact-us': {
    id: 'contact-us',
    name: 'Contact Form',
    buttonOverrides: defaultButtonOverrides,
    description:
      'A form for contacting The Reel Hope Project about a variety of opportunities from adoption to prevention.',
    formSchema: {
      firstName: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>First Name</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          className: 'col-span-1'
        },
        cleanLabel: 'First Name'
      },
      lastName: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>Last Name</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          className: 'col-span-1'
        },
        cleanLabel: 'Last Name'
      },
      email: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>Email</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          type: 'email'
        },
        cleanLabel: 'Email'
      },
      subject: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: 'Subject',
        label:
          '<span><strong>Subject</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      },
      message: {
        type: ComponentTypes.TextArea,
        required: true,
        cleanLabel: 'Message',
        label:
          '<span><strong>Message</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          rows: 12
        }
      }
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      email: yup.string().required(),
      subject: yup.string().required(),
      message: yup.string().required()
    }),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      subject: 'adoption',
      message: ''
    }
  },
  'learn-foster-care': {
    id: 'learn-foster-care',
    name: 'Learn More About Foster Care in Your State! ',
    buttonOverrides: defaultButtonOverrides,
    description: 'Learn More About Foster Care in Your State! ',
    formSchema: {
      firstName: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>First Name</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          className: 'col-span-1'
        },
        cleanLabel: 'First Name'
      },
      lastName: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>Last Name</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          className: 'col-span-1'
        },
        cleanLabel: 'Last Name'
      },
      state: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: 'Your State',
        label:
          '<span><strong>Your State</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      },
      email: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>Email</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          type: 'email'
        },
        cleanLabel: 'Email'
      },
      message: {
        type: ComponentTypes.TextArea,
        required: false,
        cleanLabel: 'Message',
        label: '<span><strong>Message</strong></span>',
        additionalProps: {
          rows: 12
        }
      }
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      state: yup.string().required(),
      email: yup.string().required(),
      message: yup.string().notRequired()
    }),
    defaultValues: {
      firstName: '',
      lastName: '',
      state: '',
      email: '',
      message: ''
    }
  },
  'adoption-guide': {
    id: 'adoption-guide',
    name: 'Get Your Free Adoption Guide Now',
    description: 'A form for getting free adoption guide information.',
    buttonOverrides: defaultButtonOverrides,
    // submissionEmailSubject: 'Your free Adoption Guide',
    // submissionFileName: 'Your Free Adoption Guide',
    // sendFileOnSubmission:
    //   'https://www.canva.com/design/DAFtHyG0kZQ/Xnb8jLDJKMkAVs7lkz3jVw/view?utm_content=DAFtHyG0kZQ&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink',
    formSchema: {
      firstName: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>First Name</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          className: 'col-span-1'
        },
        cleanLabel: 'First Name'
      },
      lastName: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>Last Name</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          className: 'col-span-1'
        },
        cleanLabel: 'Last Name'
      },
      email: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>Email</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          type: 'email'
        },
        cleanLabel: 'Email'
      },
      state: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: "Inquirer's State",
        label:
          '<span><strong>Your State</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      }
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      email: yup.string().required(),
      state: yup.string().required()
    }),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      state: ''
    }
  },
  'foster-care-guide': {
    id: 'foster-care-guide',
    name: 'Get Your Free Foster Care Guide Now',
    description: 'A form for getting free foster care guide information.',
    buttonOverrides: defaultButtonOverrides,
    formSchema: {
      firstName: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>First Name</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          className: 'col-span-1'
        },
        cleanLabel: 'First Name'
      },
      lastName: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>Last Name</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          className: 'col-span-1'
        },
        cleanLabel: 'Last Name'
      },
      email: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>Email</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          type: 'email'
        },
        cleanLabel: 'Email'
      },
      state: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: "Inquirer's State",
        label:
          '<span><strong>Your State</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      }
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      email: yup.string().required(),
      state: yup.string().required()
    }),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      state: ''
    }
  },
  'church-partner': {
    id: 'church-partner',
    name: 'Become a Supporter',
    subtitle: 'Contact us about partnership',
    description:
      'A form for contacting The Reel Hope Project about a church partnership.',
    buttonOverrides: defaultButtonOverrides,
    formSchema: {
      firstName: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>First Name</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          className: 'col-span-1'
        },
        cleanLabel: 'First Name'
      },
      lastName: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>Last Name</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          className: 'col-span-1'
        },
        cleanLabel: 'Last Name'
      },
      email: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>Email</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          type: 'email'
        },
        cleanLabel: 'Email'
      },
      groupName: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: 'Name of Business, Church, or Foundation',
        label:
          '<span><strong>Name of Business, Church, or Foundation</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      },
      city: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: 'City Location',
        label:
          '<span><strong>City Location</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      },
      state: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: "Inquirer's State",
        label:
          '<span><strong>Your State</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      },
      role: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: 'Your Role',
        label: '<span><strong>Your Role</strong></span>'
      },
      hosting: {
        type: ComponentTypes.Checkbox,
        required: true,
        label:
          "I'm interested in hosting The Reel Hope Project at my church or faith-based event"
      },
      partnership: {
        type: ComponentTypes.Checkbox,
        required: true,
        label:
          "I'm interested in discussing financial partnership with my business"
      },
      foundation: {
        type: ComponentTypes.Checkbox,
        required: true,
        label:
          "I'm interested in discussing financial partnership with my foundation"
      },
      other: {
        type: ComponentTypes.Checkbox,
        required: true,
        label: 'Other'
      },
      subject: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: 'Subject',
        label:
          '<span><strong>Subject</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      },
      message: {
        type: ComponentTypes.TextArea,
        required: true,
        cleanLabel: 'Message',
        label:
          '<span><strong>Message</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          rows: 12
        }
      }
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      email: yup.string().required(),
      groupName: yup.string().required(),
      city: yup.string().required(),
      hosting: yup.boolean().required(),
      partnership: yup.boolean().required(),
      subject: yup.string().required(),
      message: yup.string().required(),
      state: yup.string().required()
    }),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      groupName: '',
      city: '',
      hosting: false,
      partnership: false,
      subject: '',
      message: '',
      state: ''
    }
  },
  'trhp-host': {
    id: 'trhp-host',
    name: 'Host The Reel Hope Project',
    description: 'A form for contacting The Reel Hope Project about hosting.',
    buttonOverrides: defaultButtonOverrides,
    formSchema: {
      name: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: 'Name',
        label:
          '<span><strong>Your Name</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      },
      email: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: 'Email',
        label:
          '<span><strong>Email</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          type: 'email'
        }
      },
      groupName: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: 'Name of Community, Faith Group, or Event',
        label:
          '<span><strong>Name of Community, Faith Group, or Event</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      },
      city: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: 'City Location',
        label:
          '<span><strong>City Location</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      },
      state: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: "Inquirer's State",
        label:
          '<span><strong>Your State</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      },
      subject: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: 'Subject',
        label:
          '<span><strong>Subject</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      },
      message: {
        type: ComponentTypes.TextArea,
        required: true,
        cleanLabel: 'Message',
        label:
          '<span><strong>Message</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          rows: 12
        }
      }
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(),
      email: yup.string().required(),
      groupName: yup.string().required(),
      city: yup.string().required(),
      subject: yup.string().required(),
      message: yup.string().required(),
      state: yup.string().required()
    }),
    defaultValues: {
      name: '',
      email: '',
      groupName: '',
      city: '',
      subject: '',
      message: '',
      state: ''
    }
  },
  newsletter: {
    id: 'newsletter',
    name: 'The Reel Hope Project Newsletter',
    description: 'A form for signing up for The Reel Hope Project newsletter.',
    buttonOverrides: defaultButtonOverrides,
    formSchema: {
      firstName: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>First Name</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          className: 'col-span-1'
        },
        cleanLabel: 'First Name'
      },
      lastName: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>Last Name</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          className: 'col-span-1'
        },
        cleanLabel: 'Last Name'
      },
      email: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>Email</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          type: 'email'
        },
        cleanLabel: 'Email'
      },
      subject: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: 'Subject',
        label:
          '<span><strong>Subject</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      },
      message: {
        type: ComponentTypes.TextArea,
        required: true,
        label:
          '<span><strong>Message</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          rows: 12
        },
        cleanLabel: 'Message'
      },
      updates: {
        type: ComponentTypes.Checkbox,
        required: true,
        label:
          'Sign me up for monthly updates through The Reel Hope Project newsletter.'
      }
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      email: yup.string().required(),
      subject: yup.string().required(),
      message: yup.string().required(),
      updates: yup.boolean().required()
    }),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      subject: '',
      message: '',
      updates: false
    },
    saveText: 'Send'
  },
  'refer-a-child': {
    id: 'refer-a-child',
    name: 'Refer A Child',
    description: 'A form for a social working to refer a child.',
    buttonOverrides: defaultButtonOverrides,
    formSchema: {
      firstName: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>Your First Name</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          className: 'col-span-1'
        },
        cleanLabel: 'First Name'
      },
      lastName: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>Your Last Name</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          className: 'col-span-1'
        },
        cleanLabel: 'Last Name'
      },
      email: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>Your Email</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          type: 'email'
        },
        cleanLabel: 'Email'
      },
      agencyCounty: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: 'Agency/County',
        label:
          '<span><strong>Your Agency/County</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      },
      socialWorkerName: {
        type: ComponentTypes.TextField,
        required: false,
        cleanLabel:
          "Youth or Sibling Group's County Social Worker, Custodian Name, or Guardian Designee",
        label:
          "<span><strong>Youth or Sibling Group's County Social Worker, Custodian Name, or Guardian Designee (if different from above)</strong></span>"
      },
      socialWorkerEmail: {
        type: ComponentTypes.TextField,
        required: false,
        cleanLabel:
          "Youth or Sibling Group's County Social Worker, Custodian, or Guardian Designee Email",
        label:
          "<span><strong>Youth or Sibling Group's County Social Worker, Custodian, or Guardian Designee Email (if different from above)</strong></span>"
      },
      childInitials: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: "Youth or Sibling Group's Initials",
        label:
          '<span><strong>Youth or Sibling Group\'s Initials</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      },
      childAge: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel:
          "Youth or Sibling Group's Age(s) (please separate and list in the same order as name(s) above)",
        label:
          '<span><strong>Youth or Sibling Group\'s Age(s) (please separate and list in the same order as name(s) above)</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          type: 'number'
        }
      },
      childCounty: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel:
          "Youth or Sibling Group's County, Human Service Zone, or Tribe",
        label:
          '<span><strong>Youth or Sibling Group\'s County, Human Service Zone, or Tribe</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      },
      message: {
        type: ComponentTypes.TextArea,
        required: false,
        cleanLabel: 'Comments',
        label: '<span><strong>Comments</strong> (optional)</span>',
        additionalProps: {
          rows: 12
        }
      }
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required('First Name is required'),
      lastName: yup.string().required('Last Name is required'),
      email: yup.string().required('Email is required'),
      agencyCounty: yup.string().required('Agency / County is required'),
      socialWorkerName: yup.string().notRequired(),
      socialWorkerEmail: yup.string().notRequired(),
      childInitials: yup
        .string()
        .required("Your or Sibling Group's Initials are required"),
      childAge: yup.string().required('Age is required'),
      childCounty: yup.string().required('County is required'),
      message: yup.string().notRequired()
    }),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      agencyCounty: '',
      socialWorkerName: '',
      socialWorkerEmail: '',
      childInitials: '',
      childAge: '',
      childCounty: '',
      message: ''
    },
    saveText: 'Refer Child'
  },
  'waiting-child-inquiry': {
    id: 'waiting-child-inquiry',
    name: 'Licensed Individual or Family Inquiry Form',
    description: 'A form for to inquire about a child that is waiting.',
    buttonOverrides: defaultButtonOverrides,
    formSchema: {
      firstName: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>Your First Name</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          className: 'col-span-1'
        },
        cleanLabel: 'Your First Name'
      },
      lastName: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>Your Last Name</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          className: 'col-span-1'
        },
        cleanLabel: 'Your Last Name'
      },
      email: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>Your Email</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          type: 'email'
        },
        cleanLabel: 'Email'
      },
      state: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: 'Your State',
        label:
          '<span><strong>Your State</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      },
      agencyName: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: "Your Agency's Name",
        label:
          '<span><strong>Your Agency\'s Name</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      },
      socialWorkerName: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: "Your Social Worker's Name",
        label:
          '<span><strong>Your Social Worker\'s Name</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      },
      socialWorkerEmail: {
        type: ComponentTypes.TextField,
        required: false,
        cleanLabel: "Social Worker's Email",
        label:
          '<span><strong>Your Social Worker\'s Email</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      },
      childName: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: "Youth's Name You Are Inquiring About",
        label:
          '<span><strong>Youth\'s Name You Are Inquiring About</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      },
      childState: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: "Youth's State You Are Inquiring About",
        label:
          '<span><strong>Youth\'s State You Are Inquiring About</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      },
      howDidYouHear: {
        type: ComponentTypes.TextField,
        required: false,
        cleanLabel: 'How Did You Hear About Us?',
        label: '<span><strong>How Did You Hear About Us?</strong></span>'
      }
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      email: yup.string().required(),
      state: yup.string().required(),
      agencyName: yup.string().required(),
      socialWorkerName: yup.string().required(),
      socialWorkerEmail: yup.string().required(),
      childName: yup.string().required(),
      childState: yup.string().required(),
      howDidYouHear: yup.string().notRequired()
    }),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      state: '',
      agencyName: '',
      socialWorkerName: '',
      socialWorkerEmail: '',
      childName: '',
      childState: '',
      howDidYouHear: ''
    }
  },
  'unlicensed-inquiry': {
    id: 'unlicensed-inquiry',
    name: 'Unlicensed, but wanting to learn more!',
    description:
      'A form for to inquire about a child that is waiting - unlicensed',
    buttonOverrides: defaultButtonOverrides,
    formSchema: {
      firstName: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>Your First Name</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          className: 'col-span-1'
        },
        cleanLabel: 'Your First Name'
      },
      lastName: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>Your Last Name</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          className: 'col-span-1'
        },
        cleanLabel: 'Your Last Name'
      },
      email: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>Your Email</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          type: 'email'
        },
        cleanLabel: 'Email'
      },
      state: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: 'Your State',
        label:
          '<span><strong>Your State</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      },
      childName: {
        type: ComponentTypes.TextField,
        required: false,
        cleanLabel: "Youth's Name You Are Inquiring About",
        label:
          '<span><strong>Youth\'s Name You Are Inquiring About</strong> <span className="italic font-light">(If applicable; leave blank if general inquiry)</span></span>'
      },
      childState: {
        type: ComponentTypes.TextField,
        required: false,
        cleanLabel: "Youth's State You Are Inquiring About",
        label:
          '<span><strong>Youth\'s State You Are Inquiring About</strong> <span className="italic font-light">(If applicable; leave blank if general inquiry)</span></span>'
      },
      howDidYouHear: {
        type: ComponentTypes.TextField,
        required: false,
        cleanLabel: 'How Did You Hear About Us?',
        label: '<span><strong>How Did You Hear About Us?</strong></span>'
      }
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      email: yup.string().required(),
      state: yup.string().required(),
      childName: yup.string().notRequired(),
      childState: yup.string().notRequired(),
      howDidYouHear: yup.string().notRequired()
    }),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      state: '',
      childName: '',
      childState: '',
      howDidYouHear: ''
    }
  },
  'wrap-around-guide': {
    id: 'wrap-around-guide',
    name: 'Get Your Free Wrap-around Guide Now',
    description: 'A form for getting free wrap around guide information.',
    submissionEmailSubject: 'Your free Wrap Around Guide',
    submissionFileName: 'Your Free Wrap Around Guide',
    sendFileOnSubmission:
      'https://www.canva.com/design/DAFtHyG0kZQ/Xnb8jLDJKMkAVs7lkz3jVw/view?utm_content=DAFtHyG0kZQ&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink',
    buttonOverrides: defaultButtonOverrides,
    formSchema: {
      firstName: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>First Name</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          className: 'col-span-1'
        },
        cleanLabel: 'First Name'
      },
      lastName: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>Last Name</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          className: 'col-span-1'
        },
        cleanLabel: 'Last Name'
      },
      email: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>Email</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          type: 'email'
        },
        cleanLabel: 'Email'
      },
      state: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: "Inquirer's State",
        label:
          '<span><strong>Your State</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      }
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      email: yup.string().required(),
      state: yup.string().required()
    }),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      state: ''
    }
  },
  'business-partner': {
    id: 'business-partner',
    name: 'Contact Us About Becoming A Partner',
    description:
      'A form for contacting The Reel Hope Project about business partnering.',
    buttonOverrides: defaultButtonOverrides,
    formSchema: {
      firstName: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>First Name</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          className: 'col-span-1'
        },
        cleanLabel: 'First Name'
      },
      lastName: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>Last Name</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          className: 'col-span-1'
        },
        cleanLabel: 'Last Name'
      },
      email: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>Email</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          type: 'email'
        },
        cleanLabel: 'Email'
      },
      businessName: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: 'Name of Business',
        label:
          '<span><strong>Name of Business</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      },
      position: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: 'Your Position',
        label:
          '<span><strong>Your Position</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      },
      subject: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: 'Subject',
        label:
          '<span><strong>Subject</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      },
      message: {
        type: ComponentTypes.TextArea,
        required: true,
        cleanLabel: 'Message',
        label:
          '<span><strong>Message</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          rows: 12
        }
      }
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      email: yup.string().required(),
      businessName: yup.string().required(),
      position: yup.string().required(),
      subject: yup.string().required(),
      message: yup.string().required()
    }),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      businessName: '',
      position: '',
      subject: '',
      message: ''
    }
  },
  'participation-form': {
    id: 'participation-form',
    name: 'Participation Form',
    subtitle:
      'Complete the following fields to receive the Participation Form via email.',
    description: 'A form for receiving a participation form.',
    buttonOverrides: defaultButtonOverrides,
    formSchema: {
      firstName: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>Your First Name</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          className: 'col-span-1'
        },
        cleanLabel: 'First Name'
      },
      lastName: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>Your Last Name</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          className: 'col-span-1'
        },
        cleanLabel: 'Last Name'
      },
      email: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>Your Email</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          type: 'email'
        },
        cleanLabel: 'Email'
      },
      county: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: 'Agency/County',
        label:
          '<span><strong>Your Agency/County</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      },
      state: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: "Inquirer's State",
        label:
          '<span><strong>Your State</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      },
      childInitials: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: "Child's Initials",
        label:
          '<span><strong>Child\'s Initials</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      },
      childAge: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: "Child's Age",
        label:
          '<span><strong>Child\'s Age</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span> <span>Please enter a number from <strong>0</strong> to <strong>19</strong>.</span></span>',
        additionalProps: {
          type: 'number'
        }
      },
      childCounty: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: "Child's County",
        label:
          '<span><strong>Child\'s County</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      }
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      email: yup.string().required(),
      county: yup.string().required(),
      state: yup.string().required(),
      childInitials: yup.string().required(),
      childAge: yup.string().required(),
      childCounty: yup.string().required()
    }),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      county: '',
      state: '',
      childInitials: '',
      childAge: '',
      childCounty: ''
    }
  },
  'newsletter-subscribe': {
    id: 'newsletter-subscribe',
    name: 'The Reel Hope Project Newsletter',
    subtitle: 'Sign up for the latest news.',
    description: 'A form for getting The Reel Hope Project Newsletter.',
    buttonOverrides: defaultButtonOverrides,
    formSchema: {
      name: {
        type: ComponentTypes.TextField,
        required: true,
        label: '<span><strong>Name</strong></span>',
        cleanLabel: 'Name'
      },
      email: {
        type: ComponentTypes.TextField,
        required: true,
        label: '<span><strong>Email</strong></span>',
        additionalProps: {
          type: 'email'
        },
        cleanLabel: 'Email'
      }
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(),
      email: yup.string().required()
    }),
    defaultValues: {
      name: '',
      email: ''
    },
    saveText: 'Subscribe'
  },
  'not-licensed-inquiry': {
    id: 'not-licensed-inquiry',
    name: 'Interested In Fostering But Not Licensed',
    description: 'A form for to inquire about a adoption when not licensed.',
    buttonOverrides: defaultButtonOverrides,
    formSchema: {
      firstName: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>First Name</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          className: 'col-span-1'
        },
        cleanLabel: 'First Name'
      },
      lastName: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>Last Name</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          className: 'col-span-1'
        },
        cleanLabel: 'Last Name'
      },
      email: {
        type: ComponentTypes.TextField,
        required: true,
        label:
          '<span><strong>Your Email</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>',
        additionalProps: {
          type: 'email'
        },
        cleanLabel: 'Email'
      },
      state: {
        type: ComponentTypes.TextField,
        required: true,
        cleanLabel: 'Inquirer State',
        label:
          '<span><strong>Your State</strong> <span className="italic font-light" style="color:#ff0000">(Required)</span></span>'
      },
      comments: {
        type: ComponentTypes.TextArea,
        required: true,
        cleanLabel: 'Comments',
        label: '<span><strong>Comments</strong></span>'
      }
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      email: yup.string().required(),
      state: yup.string().required(),
      comments: yup.string().notRequired()
    }),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      state: '',
      comments: ''
    }
  }
};
